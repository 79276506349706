import { Seo } from '@/layout/Seo';
import { Fragment } from 'react';
import { Faq } from '@/Faq';
import { SingleQuestion } from '@/SingleQuestion';
import { ServiceHero } from '@/ServiceHero';

export default function FaqPage() {
    return (
        <Fragment>
            <Seo
                title="FAQ - Integrate Institute"
                description="Your frequently asked questions answered here."
            />
            <ServiceHero />
            <Faq>
                <SingleQuestion question="Why do you have a gym?">
                    <p>
                        We now offer personal training, strength and sport programming! Ask us about
                        pricing! We also have new machines that are great for physical therapy and
                        recovery training!
                    </p>
                </SingleQuestion>
                <SingleQuestion question="What's new?">
                    <p>
                        We now offer nutrition coaching, online and in person. Check out the
                        &ldquo;Wellness&rdquo; tab on the homepage for details! And we MOVED! New
                        address in Burnsville: 1103 West Burnsville Pkwy Suite 120.
                    </p>
                </SingleQuestion>
                <SingleQuestion question="Who do you treat?">
                    <p>We love working with motivated people of all activity levels and goals.</p>
                </SingleQuestion>
            </Faq>
        </Fragment>
    );
}
